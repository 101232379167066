import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import Seo from '../components/Seo'
import {
  Box,
  Container,
  Heading
} from '@chakra-ui/react'
import EatListingFilter from "../components/ListingCard/EatListingFilter"
import SliceZone from "../components/SliceZone/SliceZone"
import { PrismicRichText } from "@prismicio/react"



const EatPage = ({ data, location }) => {
  const document = data.allPrismicStaticPage.edges[0].node.data;
  const pageLocation = location.pathname;

  return (
    <Layout pageLocation={pageLocation}>
      <Seo title={document.page_title.text} />
           
      <Container maxW="container.xl" mb="16">
        <Box mt="8" mb="12">
        <Heading as="h1" variant="h1" my="12" textAlign="center" color="sage.700">
          {document.page_title.text}
        </Heading>
          <Box>
            <PrismicRichText field={document.page_content.richText} />
          </Box>
        </Box>
          <EatListingFilter location={location}/>
      </Container>

      <SliceZone sliceZone={document.body} />

    </Layout>
  )
}

export const query = graphql`
{
  allPrismicStaticPage(filter: {uid: {eq: "eat"}}) {
    edges {
      node {
        _previewable
        data {
          page_title {
            text
          }
          page_content {
            richText
          }
          body {
            ... on PrismicStaticPageDataBodyNewsCarousel {
              id
              slice_type
              items {
                title
                excerpt
                button_label
                button_link {
                  url
                  target
                }
                image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        quality: 8
                     )
                    }
                  }
                }
              }
            }
            ... on PrismicStaticPageDataBodyLogoCallToAction {
              id
              slice_type
              primary {
                logo {
                  alt
                  localFile {
                    publicURL
                  }
                }
                align
                content {
                  richText
                }
                button_link {
                  url
                  target
                }
                button_label
              }
            }
            ... on PrismicStaticPageDataBodyFullImageSection {
              id
              slice_type
              primary {
                text_content {
                  richText
                }
                image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
                headline {
                  text
                }
                button_link {
                  url
                }
                button_label
                button_color
              }
            }
            ... on PrismicStaticPageDataBodyBlueBoxSection {
              id
              slice_type
              primary {
                section_title {
                  text
                }
              }
              items {
                link_3 {
                  url
                }
                link_2 {
                  url
                }
                link_1 {
                  url
                }
                label_3
                label_2
                label_1
                image_3 {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                  alt
                }
                image_2 {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
                image_1 {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
              }
            }
            ... on PrismicStaticPageDataBodyAdvertisement {
              id
              slice_type
              primary {
                ad_link {
                  url
                }
                desktop_ad_image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
                mobile_ad_image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`


export default EatPage
